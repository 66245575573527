<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row align-items-center mb-10">

			<h2 class="display-4 mb-0">
				Transférer des tokens à terme échus</h2>
		</div>
		<h2>
			Principe de base</h2>
		<p>
			Un titulaire de compte veut transférer des tokens vers un autre compte. Ces tokens sont transféré immédiatement,
			mais ne seront utilisable uniquement par le titulaire du compte destination que passé un certain délai en jour.

		</p>
		<h2>
			Un exemple concret</h2>
		<p>
			Le 25 mars 2022, Madeleine veut payer Robin avec 10 Berlcoins dans 30 jours, soit le 24 avril 2022.
			<br />
		</p>

		<h3 class="mt-12">
			Situation initiale</h3>

		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/transfert-term-a-1.png" />
				<br /> <br /> <b> Le compte de Madeleine</b><br />
			</div>
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/transfert-term-b-1.png" />
				<br /> <br /> <b> Le compte de Robin</b><br />
			</div>
		</div>


		<h3 class="mt-12">
			Ordre de transfert de 10 Berlcoins dans 30 jours</h3>


		<p>Dans la section <b>Transfert</b>.</p>

		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/transfert-term-a-2.png" />
				<br /> <br />
				<b> Madeleine sélectionne la menu Transferer et payer, la liste de ses tokens s'affiche
				</b> <br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/transfert-term-a-3.png" />
				<br /> <br />
				<b>Elle sélectionne Berlcoin, les menus de Paiement s'affiche, elle appuit sur le bouton PAIEMENT A TERME.
				</b> <br />
			</div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/transfert-term-a-4.png" />
				<br /> <br />
				<b>Le formulaire s'affiche, Madeleine saisie le nombre de Berlcoin et le nombre de jour et la clé du compte de Robin puis valide.
				</b> <br />
			</div>
		</div>


		<h3 class="mt-12">
			Situation des comptes après le transfert</h3>


		<p>Dans la section <b>Dashbord</b>.</p>


		<div class="row ">
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/transfert-term-a-5.png" />
				<br /> <br />
				<b> Le compte de Madeleine à 10 Berlcoins en moins
				</b> <br />
			</div>
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/transfert-term-b-2.png" />
				<br /> <br /> <b> Le compte de Robin à un contrat de 10 Berlcoin à échéance le 24 avril 2022.</b><br />
			</div>

		</div>



		<div class="row ">
			<h3 class="mt-12">
				Coût de l'opération</h3>
			<p> Madeleine a payé 0.00001 XLM (ici à 0.20 euro pour 1 XLM ) pour l'ordre de transfert. Robin paiera dans un 30 jours 0.00001 XLM (ici à 0.20 euro pour 1 XLM ).
				<br /> <br /> Au final moins de 1 centime d'euro.
			</p>
		</div>
	</div>

</section>
</template>


<script>
export default {
	name: 'account',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
